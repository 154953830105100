.main-header {
  background: $white;
 	width: 100%;
 	position: fixed;
  height: auto;
 	left: 0;
 	top: 0;
 	z-index: 1000;
 	transition: $slow;
  &-wrap {
    background: $white;
    position: relative;
    height: auto;
    -webkit-box-shadow: 0 0 8px 0px rgba(0,0,0,.4);
    box-shadow: 0 0 8px 0px rgba(0,0,0,.4);
    @include tablet-wide {
      height: 100px;
      z-index: 1;
    }
  }
}


.primary-nav {
   z-index: 1000;
   width: auto;
   opacity: 1.0;
   position: absolute;
   right: 5rem;
  	display: none;
  	flex-direction: row;
  	flex-wrap: nowrap;
  	justify-content: center;
  	align-content: stretch;
  	align-items: flex-end;		
  	transition: $slow;
   @include tablet-wide {
      display: flex;
	    	padding-top: 2.6rem;
      left: initial;
      right: 2rem;
   }
   @include desktop {
      right: 5rem;
   }
	&-grouping {
		&.left,
		&.right {
			display: none;
			width: 100%;
			@include desktop {
				display: block;
				flex: 1 1 auto;
				align-self: auto;
			}
		}			
		&.left {
			text-align: right;
		}
		&.right {
			text-align: left;
		}	
		&.logo-container {
			flex: 0 1 8rem;
			transition: $slow;
			@include tablet {
				flex: 0 1 11rem;
			}			
			@include desktop {
				flex: 0 1 20rem;
				align-self: baseline;
			}
			@include md {
				flex: 0 1 27rem;
				align-self: baseline;
			}			
		}
	}
	&-item {
		font-size: 1.0em;
		display: inline-block;
		color: $darkgray;
		text-decoration: none;
		cursor: pointer;	
		text-transform: uppercase;
		position: relative;
		vertical-align: middle;
		@include tablet {
		}			
		@include desktop {
			font-size: 0.95em;
		}	
		@include md {
			font-size: 1.1em;
		}
		& .button {
		  padding: .75rem 1.75rem;
		  line-height: 1;
    font-size: 1.0em;
    margin-left: 1rem;
		}
		&:hover {
			.primary-nav {
				transition: $slow;
			  &-link { 
  				background: $lightestgray;
            color: $red;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&:focus {
			.primary-nav {
			  &-link { 
  				color: $primary;
      		box-shadow: inset 0 0 0 2px $primary;
			  }			  
			}				
			.primary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&.active {
  		&:before {
  			@include size(0, 0);
  			@include absolute(left 50% bottom 0%);			
  			z-index: 200;
  			border: solid transparent;
  			content: " ";
  			pointer-events: none;
  			border-color:  rgba($secondary, 0);
  			border-bottom-color: $secondary; 
  			border-width: .5rem;
  			margin-left: -.5rem;
  		}
  		&:hover {
  			border-color: darken($primary, 15%);
  			border-bottom-color: darken($primary, 15%); 
			}
		}
	}	
	&-list {
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		font-family: $nav;
      font-weight: 500;
		list-style: none;
		position: relative;
	}
	&-link {
		color: $darkgray;
      font-weight: 900;
		transition: $basic;	
		padding: 0.75rem 1.25rem;
		display: inline-block;
      line-height: 1;
		@include md {
  		   padding: 0.75rem 1.25rem;
		}
		&:focus {
  		   background: $darkgray;
         border-radius: .2rem;
  		   color: $white;
		}
	}
   &-link.more:after {
      font-family: $icon;
      content: "6";
      font-size: .75rem;
      padding: 0 0 0 .25rem;
   }
}

.primary-nav-list > li:before {
  content: "";
  width: 1px;
  height: 30px;
  margin: 0;
  background: #d6d6d6;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.primary-nav-list > li:last-child:before, .primary-nav-list > li:first-child:before {
   display: none;
}

.primary-subnav {
 background: $white;
 border-top: 8px $red solid;
	box-shadow: $shadow;
	width: 100%;
	min-width: 16rem;
	list-style: none;
	margin: 0;
	padding: 1.25rem .75rem;
	opacity: 0;
	visibility: hidden;
	@include absolute(top 100% left 50%);
	transform: translateX(-50%);
	transition: none;
	&:before {
		@include size(0, 0);
		@include absolute(left 50% bottom 100%);			
		z-index: 200;
		border: solid transparent;
		content: " ";
		pointer-events: none;
		border-color: rgba(darken($white, 10%), 0);
		border-bottom-color: $white; 
		border-width: .5rem;
		margin-left: -.5rem;	
      display: none
	}		
	&-li {
	 position: relative;
		padding: 0;
		margin: 0;
		display: block;
  border-bottom: 1px dotted $gray;
  &:last-child {
     border-bottom: 0;
  }
		&:hover {
			.secondary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}
		&:focus {
			.secondary-subnav {
				opacity: 1;
				visibility: visible;
				transition: none;
			}
		}		
	}
	&-link {
		display: block;
		padding: .5rem .75rem;
		color: $darkgray;
		text-transform: none;
		font-family: $body;
		font-size: 1.0rem;
		font-weight: 500;
		text-align: left;
		transition: $slow;
		&:hover {
   color: $white;
			background: darken($red, 15%) !important;
		}
		&:focus {
   color: $white;
			background: darken($red, 50%) !important;
		}		
	}
}

.secondary-subnav {
 background: $white;
 border-top: 0;
	box-shadow: $shadow;
	width: 100%;
	min-width: 16rem;
	list-style: none;
	margin: 0;
	padding: 1.25rem .75rem;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	top: -10px;
	left: initial;
	right: 96%;
	z-index: 1000;
	transform: none;
	transition: none;
	@include md {
	 top: 10px;
	 right: initial;
	 left: 95%;
	}
	&:before {
		@include size(0, 0);
		@include absolute(left 50% bottom 100%);			
		z-index: 200;
		border: solid transparent;
		content: " ";
		pointer-events: none;
		border-color: rgba(darken($white, 10%), 0);
		border-bottom-color: $white; 
		border-width: .5rem;
		margin-left: -.5rem;	
      display: none
	}		
	&-li {
		padding: 0;
		margin: 0;
		display: block;
  border-bottom: 1px dotted $gray;
  &:last-child {
     border-bottom: 0;
  }
	}
	&-link {
		display: block;
		padding: .5rem .75rem;
		color: $darkgray;
		text-transform: none;
		font-family: $body;
		font-size: 1.0rem;
		font-weight: 500;
		text-align: left;
		transition: $slow;
		&:hover {
   color: $white;
			background: darken($red, 15%) !important;
		}
		&:focus {
   color: $white;
			background: darken($red, 50%) !important;
		}		
	}
}

.primary-extras {
 width: auto;
 position: absolute; 
 top: 0;
 z-index: 1001;
 right: 1rem;
	padding-top: 1.25rem;
 display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	align-items: flex-end;		
	transition: $slow;
 @include tablet-wide {
    display: flex;
    padding-top: 0.25rem;
    right: 2rem;
	}		
	@include desktop {
   	right: 5rem;
	}
   &-share {
      font-size: 1.1rem;
      margin-left: 1.25rem;
      top: 0;
      position: relative;
      display: none;
      @include tablet {
         display: inline-block;
   	}	
   	@include desktop {
         display: inline-block;
   	}
   }
   &-links {
      @include tablet {
         transform: none;
      }
      &-button {
         padding: .5rem 1.0rem;
         text-transform: uppercase;
         font-size: 0.85rem !important;
         font-family: $nav;
         color: $gray;
         font-weight: 900;
         @include md {
            font-size: 0.85rem !important;
         }	
         &:focus {
            background: $darkgray;
            color: $white !important;
         }
         &:hover {
            color: $red !important;
            background: $lightgray;
         }
      }
   }
}

.primary-extras-links:after {
   content: "";
   width: 1px;
   height: 30px;
   margin: 0;
   background: #d6d6d6;
   display: inline-block;
   vertical-align: middle;
   position: relative;
   top: -2px;
}


.primary-extras .social-link {
   margin-right: 0.25rem;
   display: inline-block;
   top: 0;
   border-radius: .2rem;
   padding: .3rem;
   width: 28px;
   &:hover {
      background: white;
   }
   &.search {
      margin: 0 1rem 0 1.25rem;
      position: relative;
      top: .15rem;
   }
}
.primary-extras .social-link svg {
   width: 100%;
   fill: $gray;
}
.primary-extras .social-link:hover svg {
   fill: $red;
}
.bar {
   width: 100%;
   height: 2px;
   position: absolute;
   bottom: 0;
   background: $white;
   @include tablet-wide {
      height: 8px;
      background: $red;
   }
}

@include md {
  font-size: 1.2rem;
}

.more-arrow:after {
  content: "\276E";
  color: $gray;
  font-size: 1.0rem;
  position: absolute;
  left: 0;
  @include desktop {
   content: "\276F";
   right: 0.25rem;
   left: initial;
  }
}

.alert {
  padding: .75rem;
  text-align: center;
  font-size: 1.2rem;
  z-index: 10;
  position: relative;
  @include tablet {
    padding: 1.25rem;
  }
}
