@import url("https://use.typekit.net/uwm6iet.css");
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:ital,wght@0,500;0,900;1,500;1,900&display=swap');

@import "globals/globals";
@import "globals/normalize";
@import "partials/type";
@import "shell/header"; 
@import "shell/hamburger";
@import "shell/responsive-menu";
@import "shell/footer";
@import "shell/logo";
@import "shell/button";
@import "shell/promos";
@import "shell/forms";
@import "shell/video";

html {
	position: relative;
}
body {
	font-family: $body;
	color: $text;
	text-align: center;
	&.mobile-menu-active {
		overflow: hidden;
	}
}
.wrap {
   max-width: $max-width;
   margin: 0 auto;
   position: relative;
}
.screen-reader {
	@include screen-reader-text;
}

.skip-to-main {
	background: $white;
	@include fixed(left .5rem top .5rem);
	z-index: 1000000;
	padding: 1rem;
	color: $blue;
	text-transform: uppercase;
	display: block;
	transition: $slow;
	transform: translateY(calc(-100% - .5rem));
	border-radius: 2rem;
	&:focus, &:focus-within {
		transform: translateY(0);
		outline: 2px solid $blue;
	}
}

.anchor {
    display: block !important;
    margin-top: -8.5rem;
    position: absolute;
    height: 1px;
    width: 100%;
    z-index: -1;
    background: transparent;
}
