.photo-promo {
   background-color: $blue;
	position: relative;
	opacity: 0;
	transition: $slow;
	overflow: hidden;
	&.add_top_padding {
  	margin-top: 1.5rem;
  	@include tablet { 
  		margin-top: 2rem;		
  	}
  	@include desktop {
  		margin-top: 4rem;		
  	}
  	@include md { 
  		margin-top: 5rem;				
  	}	
  	@include lg { 
  		margin-top: 6rem;						
  	}
  	@include xl { 
  		margin-top: 7rem;								
  	}		
	}
	&.not_full_width {
	 max-width: $max-width;
		margin: 1rem 1rem 0;
		@include tablet {
  		margin: 2rem auto;
		}
	}
	&.reveal {
		opacity: 1;
		.photo-promo-title {
			@extend %slide-up-reveal;
		}
	}
   &.sidebar-promo {
      background-color: white;
      & .photo-promo-item {
         background-color: $blue;
         margin-bottom: 1rem;
      }
      & .photo-promo-item-photo {
         opacity: 0.5 !important;
      }
   }
	&-title {
		font-family: $headline;
		margin: 1rem auto;
		@extend %slide-up;
		color: $white;
		@extend %headline2;
		@include desktop {
			margin: 2rem auto;			
		}			
		&-link {
			color: $white;
			transition: $slow;
			padding: 0 .5rem;
			&:hover {
				color: $primary;
			}
		}
	}
	&-grid {
		width: 100%;
		@include tablet {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
		}
	}
	&-item {
		position: relative;
		overflow: hidden;
		z-index: 100;
      max-height: 400px;
		@include tablet {
		   flex: 1;
         max-height: initial;
			transition-delay: .1s;
			&:nth-child(2) {
				transition-delay: .2s;
			}
			&:nth-child(3) {
				transition-delay: .3s;
			}	
		}
		@include md {
         flex: 1;
		}
		&.green {
			background: $primary;
		}
		&.blue {
			background: $secondary;
		}	
		&.dark-green {
			background: darken($primary, 20%);
		}
		&.dark-blue {
			background: darken($secondary, 20%);
		}			
		&.dark-gray {
			background: darken($darkgray, 20%);
		}		
		&.gray {
			background: $gray;
		}		
		&.black {
			background: $black;
		}				
		&.white {
			background: $white;
			.photo-promo-item {
				&-headline,
				&-blurb {
					color: $gray;
				}					
			}			
		}			
		&-aspect {
			@include aspect(16,9);
			@include size(100%);
		}					
		&-center {
			@include size(100%, 100%);
			@include absolute(top 0 left 0);	
			z-index: 400;
			align-items: center;
			display: flex;
			justify-content: center;
			overflow: hidden;	
			padding: 1.5rem;
         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.5+100 */
         background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
         background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
         background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
			@include tablet {
				padding: 1rem;
			}		
			@include md {
				padding: 2rem;
			}
     		&:focus {
         	box-shadow: inset 0 0 0 8px $blue;
     		}
         &.gradient {
            text-align: left;
            align-items: normal;
            justify-content: left;
         }
		}
      &:nth-child(1) .photo-promo-item-center.gradient {
         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#214e8c+10,214e8c+60&1+10,0+60 */
         background: -moz-linear-gradient(15deg,  rgba(33,78,140,1) 20%, rgba(33,78,140,0) 60%); /* FF3.6-15 */
         background: -webkit-linear-gradient(15deg,  rgba(33,78,140,1) 20%,rgba(33,78,140,0) 60%); /* Chrome10-25,Safari5.1-6 */
         background: linear-gradient(15deg,  rgba(33,78,140,1) 20%,rgba(33,78,140,0) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#214e8c', endColorstr='#00214e8c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
      &:nth-child(2) .photo-promo-item-center.gradient {
         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#90fff6+10,90fff6+60&1+10,0+60 */
         background: -moz-linear-gradient(15deg,  rgba(144,255,246,1) 20%, rgba(144,255,246,0) 60%); /* FF3.6-15 */
         background: -webkit-linear-gradient(15deg,  rgba(144,255,246,1) 20%,rgba(144,255,246,0) 60%); /* Chrome10-25,Safari5.1-6 */
         background: linear-gradient(15deg,  rgba(144,255,246,1) 20%,rgba(144,255,246,0) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#90fff6', endColorstr='#0090fff6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
         & .button {
            background: $primary !important;
            & .button-text { 
               color: $white !important;     
            }
         }
      }
      &:nth-child(3) .photo-promo-item-center.gradient {
         /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4d98e8+10,4d98e8+60&1+10,0+60 */
         background: -moz-linear-gradient(15deg,  rgba(77,152,232,1) 20%, rgba(77,152,232,0) 60%); /* FF3.6-15 */
         background: -webkit-linear-gradient(15deg,  rgba(77,152,232,1) 20%,rgba(77,152,232,0) 60%); /* Chrome10-25,Safari5.1-6 */
         background: linear-gradient(15deg,  rgba(77,152,232,1) 20%,rgba(77,152,232,0) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d98e8', endColorstr='#004d98e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
		&-text {
			transition: $bounce;
			z-index: 100;
			position: relative;
			@extend %slide-up;			
				margin: 0 auto;
            bottom: 2rem;
            position: absolute;
			@include desktop {
				max-width: $tablet;
			}	
         & .left {
            text-align: left;
         }
		}	
		&-link {
			z-index: 100;
			position: relative;
			display: block;
			margin: 0 auto;
			padding: 2rem 0;
			@include tablet {
				padding: 2rem 0;
			}		
			@include desktop {
				padding: 3rem 0;
			}		
			@include lg {
				padding: 4rem 0;
			}					
		}		
		&-headline {
			font-family: $headline;			
			color: $white !important;
			transition: $bounce;
			@extend %headline3;
		}	
		&-subheadline {
			font-family: $headline;
         font-size: 1rem;
         line-height: 1.2;
         font-weight: 700;
			color: $white;
			transition: $bounce;
			margin-top: 1rem;
			display: block;
			@extend %headline5;
			@include tablet {
				margin-top: 1.25rem;
			}		
			@include desktop {
				margin-top: 1.5rem;
			}					
		}				
   	&-subheadline2 {
			font-family: $headline;
         font-size: 1rem;
         line-height: 1.2;
         font-weight: 700;
			color: $white;
			transition: $bounce;
			margin: -.5rem 0 1rem 0;
			display: block;
			@extend %headline5;
			@include tablet {
				margin: -.75rem 0 1.25rem 0;
			}		
			@include desktop {
				margin: -1rem 0 1.5rem 0;
			}					
		}				
		&-blurb {
			font-family: $body;			
			color: $white;
			display: block;
			transition-delay: .4s !important;
			margin: .75rem auto;
			font-size: 1rem;
			line-height: 1.3;
			text-align: center;
			@include tablet {
				margin: .75rem auto;				
			}		
			@include lg {
				margin: 1rem auto;				
				font-size: 1.3rem;
			}					
		}		
		&-photo {
			@include size(100%, 100%);
			@include absolute(top 0 left 0);	
			z-index: 1;
			background-repeat: no-repeat;  
			background-position: center center;
			background-size: cover;  		
			transition: $slow; 
			opacity: 0.9;
			transform: scale(1.2);			
		}
		&:hover {
			.photo-promo {
				&-item {
					&-headline {}
					&-photo {
						opacity: 1.0 !important;
						transform: scale(1.2) !important;
					}					
				}
			}
		}	
		&.square {
			.photo-promo-item-aspect {
				@include aspect(5,4);
			}			
		}
		&.letterbox {
			.photo-promo-item-aspect {
				@include aspect(4,3);
			}			
		}
   	&.widescreen {
			.photo-promo-item-aspect {
				@include aspect(16,9);
			}			
		}
		&.flex-with-content {
			.photo-promo-item {
				&-aspect {
					padding-top: 0 !important;
				}
				&-center {
					@include relative(top 0 left 0);	
					@include phone {
					  padding-top: 3rem;
					  padding-bottom: 3rem;
					}
				}
			}			
		}	
		&.match-sibling {
			.photo-promo-item-center {
				@include phone {
					@include relative(top 0 left 0);	
				}
			}			
		}				
		&.wide {
			@include tablet {
				flex: 0 0 66%;
			}
		}
		.button {
			&-block {
			  margin-top: .5rem;
				transition-delay: 1s;
			}
		}			
		&.reveal {
			.photo-promo-item {
				&-text {
					@extend %slide-up-reveal;
				}					
				&-photo {
					opacity: 0.9;
					transform: scale(1);
					&.no_dim {
					  opacity: 1;
					}
				}
			}
			.button {
				&-block {
					@extend %slide-up-reveal;
				}
			}
		}
		&.multi-button {
  		&:hover {
  			.photo-promo {
  				&-item {
  					&-headline {
  						transform: scale(1) !important;
  					}		
  					&-photo {
  						opacity: .4 !important;
  						transform: scale(1) !important;
    					&.no_dim {
    					  opacity: 1;
    					}      						
  					}					
  				}
  			}
  		}	
    }
	}
}
