.body-form {
  z-index: 100;
  position: relative;
  &.long {
		transition: none;
  }
	&-grid {
		width: 100%;
		@include tablet {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
			align-content: stretch;
			margin-bottom: 1rem;
		}
		&-masonry {
			flex-wrap: wrap;
		}
	}	
	&-masonry {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: flex-end;
		align-content: flex-end;
		margin-top: .5rem;
		.body-form-field {
		  width: 100%;
		  margin: .5rem 0;
		  @include tablet {
  		  width: 50%;
  		  &.wide {
  		    width: 100%;
  		    padding-left: 0 !important;
  		    padding-right: 0 !important;
  		  }
  		  margin: 1rem 0;
		    &:nth-child(even) {
		      padding-left: .75rem;
        	div.error {
        	  left: .75rem;
        	}	 
        	.body-form-label.hidden-label {
        	  left: calc(.75rem + 1px);
        	}	 		
		    }
		    &:nth-child(odd) {
		      padding-right: .75rem;
		    }		    
		  }
		}
	  &.fours {
  		.body-form-field {
    	  width: 50%;
		    &:nth-child(even) {
		      padding-left: .25rem;
		    }
		    &:nth-child(odd) {
		      padding-right: .25rem;
		    }		     	  
  		  @include tablet {
      	  width: 25%;
  		    &:nth-child(4n+1) {
  		      padding-right: .75rem;
  		    }
  		    &:nth-child(4n+2),
  		    &:nth-child(4n+3) {
  		      padding-left: .3725rem;
  		      padding-right: .3725rem;
  		    }		   
  		    &:nth-child(4n+4) {
  		      padding-left: .75rem;
  		    }		  		    
  		  }    	  
  		}
	  }		
	}		
	&-col {
		position: relative;
		color: $white;
		text-align: left;
		@include tablet {
			flex: 1;
			padding: 1rem;
		}	
		&.nopad {
		  @include tablet {
  		  padding: 0;
  	    .body-form-field {
  	      &:first-of-type {
  	        margin-top: 0;
  	      }
  	    }
		  }
		}		
		&.highlighted {
		  background: $lightestgray;
		  border: 1px solid $lightgray;
		  padding: 1rem 1.5rem;
		  @include tablet {
  		  margin-left: 1rem;
  		  padding: 1.25rem 2rem;
		  }
		  @include desktop {
  		  margin-left: 1.5rem;
		  }		  
		}
		.body-form-field {
		  &:last-of-type {
		    @include tablet {
  		    margin-bottom: 0;
		    }
		  }
		}
	}
  &-grouping {
    padding: 0 0 1rem;
    @include tablet {
      padding: 0 0 1.5rem;
    }
    @include desktop {
      padding: 0 0 2rem;
    }   
    &:last-of-type {
      padding: 0 !important;
    }
    &-heading {
      @extend %headline5;
      margin-bottom: .5rem;
    }
    &.highlighted {
		  background: $lightgray;
		  border: 1px solid $gray;
		  padding: 1.5rem 2rem;
		  text-align: center;
    }
  }
	&-field {
	  padding: 0 0;
		margin: .25rem 0;
	  position: relative;
    &:last-of-type {
  	  @include tablet {
  	  }
    }	   	  
	  &.text-entered {
	    .body-form-label {
	      opacity: 1;
	      visibility: visible;
	    }
	  }
  	div.error {
  	  background: $red;
  	  padding: .15rem;
  	  font-size: 70%;
  	  color: $white;
  	  display: inline-block;
  		@include absolute(bottom 0 left 0);	
  		transform: translateY(-0%);
  	}	  
	}	
	&-label {
	  background: $white;
	  padding: .25rem;
	  font-size: 80%;
		@include absolute(top 0 left 1px);	
		transform: translateY(-50%);
	  display: block;
	  color: $gray;
	  line-height: 1;
	  cursor: pointer;
	  transition: $slow;
    opacity: 0;
    visibility: hidden;
	  &:hover {
	    color: $primary;
	  }
	  &.revealed {
  	  font-size: 100%;
	    position: relative;
	    top: auto;
	    left: auto;
	    transform: translateY(0);
	    opacity: 1;
	    visibility: visible;
	    padding-left: 0;
	  }
	  small {
	    display: block;
	  }
	  a {
	    color: $primary;
	    transition: $slow;
	    &:hover {
	      background: $secondary;
	    }
	  }
	}
	&-input,
	&-textarea,
	&-select {
	  font-family: $body;
	  width: 100%;
	  background: $white;
	  border: 1px solid $gray;
	  color: $gray;
	  padding: 1rem;
  	@extend %basic-text;
	  transition: $bounce;
	  cursor: pointer;
		&:hover {
			border-color: $primary;
			color: $primary;
			@include placeholder {
				color: $primary;
			}
		}
		&:focus {
			background: $white;
			border-color: $black;
			color: $secondary;
			@include placeholder {
				color: $secondary;
			}					
		}
		&.error {
		  border-color: $primary;
		}
	}
	&-textarea {
	  height: 10rem;
	}
	&-radio {
	  &-item {
	    padding: .35rem;
	    position: relative;
	  }
	  &-input {
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0;	
      &:hover {
        .body-form-radio-button {
          background-color: $primary;
        }
      }      
      &:checked + .body-form-radio-label {
        color: $primary;
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
	  }
	  &-label {
	    cursor: pointer;
	    transition: $slow;
	    display: block;
	    padding-left: 2.3rem;
	    position: relative;
	    text-align: left;
	    &:hover {
	      color: $primary;
        &:before {
          box-shadow: .1rem .1rem .5rem 0 rgba(0,0,0,.1);
        }	  	      
	    }
	    &:before,
	    &:after {
        content: "";
        display: block;
        border-radius: 50%;
    		transform: translateY(-50%);
    	  transition: $bounce;
	    }
      &:before {
        @include absolute(left .5rem top 50%);
    		@include size(1.5rem);
        background-color: $white;
        border: 1px solid $lightgray;
      }	   
      &:after {
        background: $primary;    
        @include absolute(left 1.25rem top 50%);
    		@include size(.75rem);
    		transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        visibility: hidden;
        border-radius: 50%;
        z-index: 100;
      }      
	  }
	}
	&-button {
	  &-row {
	    padding: .25rem 0 1rem;
	    text-align: center;
	    @include tablet {
       text-align: left;
  	    padding: .25rem 0 0;
	    }
	    @include tablet {
  	    padding: .25rem 0 0;
	    }	    
	  }
	  font-family: $headline;
	  background: $primary;
		color: $white;	
		padding: 1rem 1.75rem;
		border: none;
		margin: 0 auto;
		text-transform: uppercase;
  	@extend %headline6;
  	cursor: pointer;
	  transition: $bounce;
		&:hover {
			background: darken($primary, 20%);
			color: $white;
			transform: scale(1.05);
		}
		&:active {
			background: darken($primary, 30%);
			transform: scale(.95);
		}		
	}	
	&-disclaimer {
	  padding-top: 1rem;
	  font-size: 80%;
	  text-align: center;
    color: $gray;
    max-width: $tablet;
    margin: 0 auto;
    @include tablet {
	    padding-top: 1.5rem;
    }
    @include tablet {
	    padding-top: 2rem;
    }	        
	  a {
	    color: $darkgray;
	    transition: $slow;
	    &:hover {
	      color: $primary;
	    }
	  }
	}
	&-thanks {
  	@extend %block-padding-small;
	  width: 100%;
	  opacity: 0;
	  visibility: hidden;
		@include absolute(top 0 right 0);	
		transition: $slow;
		transition-delay: .4s;
		z-index: 1;
		text-align: center;
  	@extend %basic-text;
    h2, h3 {
    	@extend %headline2;
    	color: $gray;
    	margin-bottom: 1rem;
    }
    &.long {
      opacity: 1;
      visibility: visible;
  		@include relative(top auto right auto);	
  		transition: none;
  		padding-top: 0;
  		padding-bottom: 0;
    }
	}	
  &-container {
  	@extend %block-padding-small;
  	max-width: $desktop;
  	margin: 0 auto;
  	text-align: left;
  	transition: $slow;  
  	position: relative;
  	@extend %slide-up;
  	&.no_top_padding { 
  	  padding-top: 0;
  	}
  	&.reveal {
    	@extend %slide-up-reveal;
  	}
  	&.submitted {
  	  .body-form {
  	    opacity: 0;
  	    visibility: hidden;
		    z-index: 1; 
		    height: 10rem;
  	    &-thanks {
    	    opacity: 1;
    	    visibility: visible;
    	    z-index: 100;
  	    }
  	  }
  	}
  }	
  .g-recaptcha {
    margin: 1rem auto .75rem;
    text-align: center;
    display: block;
    @include desktop {
      margin: 2rem auto 1.25rem;
    }
    div {
      margin: 0 auto;
    }
  }
}

#search {
 padding: 1.5rem;
 background: $lightgray;
 & .fields {
  margin-bottom: .5rem;
 }
}

.FR-form {
  position: relative;
  width: 100%;
  margin: 0 auto;
  border: 1px #ccc solid;
  border-radius: 1rem;
  padding: 1rem;
  @include desktop {
    width: 85%;
    border-radius: 2rem;
    padding: 2rem;
  }	  
  & .error {
    color: red;
  }
  &-row {
    position: relative;
    display: block;
    padding: 1.25rem 0;
    @include tablet {
      display: grid;
      grid-template-columns: 3fr 4fr;
      grid-gap: 1.5rem;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #ddd;
      position: absolute;
      bottom: 0;
      @include desktop {
        width: 80%;
        margin: 0 10%;
      }
    }
  }
  &-label {
    text-align: left;
    font-weight: 700;
    @include tablet {
      text-align: right;
      font-weight: 400;
    }
    & .error {
      display: block;
      font-size: 60%;
      text-transform: uppercase;
    }
  }
  &-input {
    text-align: left;
    padding-left: 3rem;
    @include tablet {
      padding-left: 0;
    }
    &-local {
      width: 8rem;
    } 
    &-years {
      width: 3rem;
    }
    &-age {
      width: 3rem;
    }
    &-row {
      &.yes_no {
        display: flex;
        column-gap: 2rem;
      }
      &-add {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 70%;
        color: #0066FF;
        text-align: right;
        display: block;
        width: 100%;
        padding: 1.5rem 0 0 0;
      }
    }
  }
  &-button {
    &-row {
      padding: 2rem 0 0 0;
      display: block;
      text-align: center;
      @include tablet {
      }
      @include tablet {
      }	    
    }
  }	
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
