.main-logo {
 width: 170px;
 margin: 0 auto;	
 display: inline-block;
 position: relative;
 z-index: 200;
 transition: $slow;
 top: 0;
 left: calc(-50% + 85px);
	padding: 10px 15px;
	&:focus {
		box-shadow: inset 0 0 0 2px $primary;
	}
   @include tablet {
      width: 200px;
      padding: 15px 20px;
      left:  initial;
   }
   @include tablet-wide {
      padding: 20px 30px;
      width: 260px;
      height: 120px;
      position: absolute;
      left: 3rem;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#86002b+0,710024+100 */
      background: #86002b; /* Old browsers */
      background: -moz-linear-gradient(top,  #FFFFFF 0%, #c8c8c8 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #FFFFFF 0%,#c8c8c8 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  #FFFFFF 0%,#c8c8c8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86002b', endColorstr='#c8c8c8c8c8c8',GradientType=0 ); /* IE6-9 */
      -webkit-box-shadow: 0 0 12px 4px rgba(0,0,0,.15);
      box-shadow: 0 0 12px 4px rgba(0,0,0,.15);
	}
	@include desktop {
      left: 5rem;
	}
	.svg {
		width: 100%;
		fill: $primary;
		transition: $basic;
	}
	&:hover {
		.svg {
			fill: $secondary;
		}
	}
}
.home .main-logo {
   @include tablet {
   }
   @include tablet-wide {
   }
}

.fixed .main-logo {
}
