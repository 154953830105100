%footer-link {
	color: $lightblue;
	transition: $basic;
	margin: 0 auto;
	&:hover {
		color: $white;
	}
	&:focus {
   color: $white;
   box-shadow: 0 0 0 1px $white;
	}	  	
	@include tablet {
		font-size: .8rem;
	}
	@include desktop {
		font-size: 1rem;
	}	
}
.site-footer {
   color: $white;
   border-top: 8px $red solid;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000222+20 */
   background: #000000; /* Old browsers */
   background: -moz-linear-gradient(top,  #000000 0%, #222222 20%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  #000000 0%,#222222 20%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  #000000 0%,#222222 20%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#222222',GradientType=0 ); /* IE6-9 */
   padding: 2rem 0;
	  &-grid {
	   @extend %block-padding;
		margin: 0 auto;
   	@include tablet {
         display: grid;
         grid-template-columns: repeat(4, 1fr);
         grid-template-rows: repeat(3, 1fr);
         grid-column-gap: 0px;
         grid-row-gap: 0px;
		}
      & .div1 { 
         grid-area: 1 / 1 / 2 / 5; 
         @include tablet-wide { grid-area: 1 / 1 / 2 / 2; }
         font-size: 0.9rem;
         line-height: 1.25rem;
         & a {
           color: $lightblue;
           text-decoration: underline;
         }
      }
      & .div2 { 
         grid-area: 2 / 1 / 3 / 2; 
         @include tablet-wide { grid-area: 1 / 2 / 2 / 3; }
      }
      & .div3 { 
         grid-area: 2 / 2 / 3 / 3; 
         @include tablet-wide { grid-area: 1 / 3 / 2 / 4;  }
      }
      & .div4 { 
         grid-area: 2 / 3 / 3 / 4; 
         @include tablet-wide { grid-area: 1 / 4 / 2 / 5; }
      }
      & .div5 { 
         grid-area: 2 / 4 / 3 / 5; 
         @include tablet-wide { grid-area: 1 / 5 / 2 / 6; }
      }
      & .div6 { 
         grid-area: 3 / 1 / 4 / 5; 
         @include tablet-wide { grid-area: 1 / 6 / 2 / 8; }
      }
		@include tablet-wide {
         display: grid;
         grid-template-columns: repeat(5, 1fr);
         grid-template-rows: 1fr;
         grid-column-gap: 10px;
         grid-row-gap: 10px;
		}	
		@include desktop {
			max-width: $desktop-medium;
		}			
	}
	&-col {
		text-align: center;
		margin: 0 auto;
      padding: 0 0 3em 0;
		@include tablet {
		   text-align: left;
			margin: 0 2rem 0 0;	
         padding: 0;
		}
      
	}	
	&-nav-list {
		text-align:  center;
  list-style: none;
  margin: 0;
  padding: 0;
		&-link {
 			@extend %footer-link;
 			display: block;
 			margin: 0 0 .5rem 0;
    font-weight: 700;
    text-decoration: underline;
    
 			@include tablet {
     font-size: 0.9rem;
     display: table;
     font-weight: 400;
 			}
    &-title {
      color: $white;
      font-family: $nav;
      font-weight: 900;
      letter-spacing: -.5px;
      font-size: 1.1rem;
      padding: 0 0 .5rem 0;
      text-transform: uppercase;
      display: block;
      &:focus {
         color: $white;
         box-shadow: 0 0 0 1px $white;
      }	  	
      &:hover {
         color: $white;
      }
    }
    &-secondary-subnav {
      margin: 0 0 .75rem 0;
      padding: 0;
      @include tablet {
        padding-left: 1.5rem;
      }
      &-li {
        &:marker {
        list-style: none !important;
       }
      }
      &-link {
        font-size: 0.8rem;
        color: $lightgray;
        text-decoration: underline;
        @include tablet {
         font-size: 0.9rem;
         color: $lightblue;
        }
      }
    }
		}
      @include tablet {
         text-align: left;
      }
	}
	.social {
		margin: 1.5rem 0 1.5rem 0;
		@include tablet {
			margin-bottom: 0;
			margin: 1rem 0 1rem 0;			
		}
		@include desktop {
			margin: 1.5rem 0 1.5rem 0;			
		}				
		&-link {
			@include size(1.75rem, 1.75rem);
			@include tablet {
				@include size(1.35rem, 1.35rem);				
			}	
			@include desktop {
				@include size(1.75rem, 1.75rem);
			}				
		}
	}
   &-callout {
      color: $white;
      font-family: $nav;
      font-size: 1.1rem;
      margin: 0 0 .5rem 0;
      text-transform: uppercase;
      display: block;
      &:hover {
         color: $white;
      }
   }
}

.footer-extras-share {
   font-size: 1.0rem;
   margin-top: .5rem;
   position: relative;
   @include md {
  	font-size: 1.1rem;
	}
}

.footer-extras-share .social-link {
   margin-right: 0.2rem;
   display: inline-block;
   border-radius: 0;
   padding: 0;
   width: 20px;
}
.footer-extras-share .social-link svg {
   width: 100%;
   fill: $gray;
}
.footer-extras-share .social-link:hover svg {
   fill: $white;
}

.paidfor {
	padding: .75rem;
	line-height: 1.3;
	border: 1px solid rgba($gray,.4);
	margin-bottom: 1.25rem;
	@include tablet {
		font-size: .7rem;
	}	
	@include desktop {
		font-size: 1rem;
	}		
}

.copyright {
   width: 100%;
   
   text-align: left;

	&-content {
		padding: 2rem;
      color: $white;
      margin: 0 auto;
		@include tablet {
			font-size: .75rem;
		}	
		@include desktop {
			font-size: 0.8rem;
         max-width: $desktop-medium;
		}			
	}
	&-nav {
		&-link {
         color: $lightblue;
         font-size: 0.8rem;
			@extend %footer-link;
			display: inline-block;
		}
	}
}
